import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useToast } from '_/hooks/use-toast'

import { Button, Grid } from '@mui/material'
import { Datagrid } from '@refera/ui-web'

import HeaderTitle from '_/components/header-title'

import useStyles from './styles'

import { groupColumns, TABLE_SX } from './constants'
import { navigate, useParams } from '@reach/router'
import { getGroupActionPermissionsSelector, getGroupSelector } from '_/modules/group/selectors'
import {
  getGroupActionPermissionList,
  getGroupById,
  updateGroupPermission,
} from '_/modules/group/actions'

export function GroupPermissionsActions() {
  const groupsActions = useSelector(getGroupActionPermissionsSelector)
  const group = useSelector(getGroupSelector)

  const { groupId } = useParams()

  const dispatch = useDispatch()
  const styles = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [orderBy, setOrderBy] = useState('')
  const [formState, setFormState] = useState([])
  const [formStateDefault, setFormStateDefault] = useState(groupsActions?.results || [])

  function handleSetValue(row, field, value) {
    setFormState(prevState => {
      const itemIndex = prevState.findIndex(item => item.id === row?.id)
      if (itemIndex === -1) {
        // If the item doesn't exist, add it to the array
        return [
          ...prevState,
          {
            id: row?.id,
            [field]: value,
          },
        ]
      }
      // If the item exists, update it
      return prevState.map((item, index) =>
        index === itemIndex ? { ...item, [field]: value } : item
      )
    })
  }

  const { showToast } = useToast()

  const getGroupsActions = useCallback(() => {
    setIsLoading(true)

    return dispatch(getGroupActionPermissionList(groupId, { pageSize, page: page + 1, orderBy }))
      .then(data => {
        setFormStateDefault(data?.results || [])
        return setIsLoading(false)
      })
      .catch(() => {
        showToast({ type: 'error', message: 'Ocorreu um erro ao buscar os registros.' })
        return setIsLoading(false)
      })
  }, [dispatch, pageSize, page, orderBy])

  const goBack = () => {
    window?.history?.length <= 2 ? navigate(`/`) : navigate(-1)
  }

  const handleOrderBy = useCallback(
    orderObj => {
      const order = orderObj[0]
      if (!order) {
        handleOrderBy('permissionName')
        return
      }
      const { field, sort } = order
      sort === 'desc' ? setOrderBy(`-${field}`) : setOrderBy(field)
    },
    [setOrderBy]
  )

  const handleClickSave = useCallback(() => {
    dispatch(
      updateGroupPermission({
        groupPermissions: formState,
      })
    )
      .then(() => {
        showToast({
          type: 'success',
          message: 'Campos salvos com sucesso.',
        })
        return setIsLoading(false)
      })
      .catch(() => {
        showToast({
          type: 'error',
          message: 'Ocorreu um erro ao salvar os dados. Tente novamente mais tarde.',
        })
        return setIsLoading(false)
      })
  }, [formState, groupId])

  function handleClickCancel() {
    setIsLoading(true)
    setFormState([])
    setFormStateDefault([])
    getGroupsActions()
  }

  useEffect(() => {
    getGroupsActions()
  }, [orderBy, page, pageSize])

  const getGroup = useCallback(() => {
    if (!group?.id) {
      setIsLoading(true)

      return dispatch(getGroupById(groupId))
        .then(() => {
          return setIsLoading(false)
        })
        .catch(() => {
          showToast({ type: 'error', message: 'Ocorreu um erro ao buscar os profiles.' })
          return setIsLoading(false)
        })
    }
    return null
  }, [dispatch])

  const groupName = useMemo(() => {
    const name = group?.desc

    return name ?? ''
  }, [group, groupId])

  useEffect(() => {
    getGroup()
  }, [])

  return (
    <Grid className={styles.page}>
      <HeaderTitle
        title={`Permissões de acesso a ações do Grupo ${groupName}`}
        backButtonAction={goBack}
      >
        <Grid style={{ display: 'flex', gap: '8px' }}>
          <Button className={[styles.buttonRed, styles.button]} onClick={() => handleClickCancel()}>
            Cancelar
          </Button>
          <Button className={[styles.buttonFullBlue, styles.button]} onClick={handleClickSave}>
            Salvar
          </Button>
        </Grid>
      </HeaderTitle>

      <Grid className={styles.tableContainer}>
        <Grid className={styles.mainTable}>
          <Datagrid
            initialState={{
              sorting: {
                sortModel: [{ field: 'permissionName', sort: 'asc' }],
              },
            }}
            loading={isLoading}
            rows={formStateDefault}
            columns={groupColumns(handleSetValue, formState)}
            rowCount={groupsActions?.count || 0}
            sx={TABLE_SX}
            noRowsLabel="Nenhum resultado encontrado"
            paginationMode="server"
            onPageChange={setPage}
            page={page}
            onPageSizeChange={setPageSize}
            pageSize={pageSize}
            onSortModelChange={handleOrderBy}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
