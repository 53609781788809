import React, { useCallback, useState, useMemo, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { Button, Datagrid } from '@refera/ui-web'
import { FilterSearch as FilterIcon } from '@refera/ui-icons'
import Theme from '@refera/ui-core'
import { useDispatch, useSelector } from 'react-redux'

import HeaderTitle from '_components/header-title'
import { ROUTES } from '_utils/constants'
import {
  getHelpRequests,
  GET_HELP_REQUESTS,
  setDefaultHelpRequestFilter,
  updateHelpRequestFilter,
} from '_modules/helpRequests/actions'
import { getAgencies, GET_AGENCIES } from '_modules/agency/actions'
import useFetchCall from '_hooks/use-fetch-call'
import useRolePermission from '_hooks/use-role-permission'
import useToggle from '_hooks/use-toggle'
import { userRoleSelector, userSelector } from '_modules/authentication/selectors'
import { getMessageSubjectOptions } from '_modules/messages/actions'
import Filters from './filters/index'
import {
  getHelpRequestFilterSelector,
  getHelpRequestCountSelector,
  getHelpRequestIsFilterDirtySelector,
  getHelpRequestListSelector,
} from '_modules/helpRequests/selectors'

import { RequestsColumns } from './utils/constants'
import useStyles, { datagridStyle } from './styles'

const HelpRequestsScreen = () => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const userData = useSelector(userSelector)
  const userRole = useSelector(userRoleSelector)
  const helpRequests = useSelector(getHelpRequestListSelector)
  const requestCount = useSelector(getHelpRequestCountSelector)
  const isFilterDirty = useSelector(getHelpRequestIsFilterDirtySelector)
  const filters = useSelector(getHelpRequestFilterSelector)
  const { isAdmin, isTradesman, isIntermediary } = useRolePermission()
  const [isLoadingGetHelpRequests] = useFetchCall(GET_HELP_REQUESTS.ACTION)
  const [isLoadingGetAgencies] = useFetchCall(GET_AGENCIES.ACTION)
  const [filterDrawerOpen, toggleFilterDrawerOpen] = useToggle()
  const [page, setPage] = useState(filters?.page || 0)
  const [pageSize, setPageSize] = useState(filters?.pageSize || 10)
  const [orderBy, setOrderBy] = useState(filters?.orderBy || '')
  const [ready, setReady] = useState(false)

  useEffect(() => {
    if (userRole && userData && !ready) {
      dispatch(getMessageSubjectOptions())

      if (!isFilterDirty) {
        dispatch(
          setDefaultHelpRequestFilter({
            isAdmin,
            isIntermediary,
            isTradesman,
            userId: userData.id,
          })
        )
      }

      if (isIntermediary || isAdmin || isTradesman) {
        setReady(true)
      }
    }
  }, [userData, userRole, ready])

  useEffect(() => {
    if (ready && !isLoadingGetHelpRequests) {
      dispatch(getHelpRequests({ ...filters, page: page + 1, pageSize, orderBy }))
      dispatch(updateHelpRequestFilter({ ...filters, page, pageSize, orderBy }))
    }
  }, [ready, page, pageSize, orderBy])

  useEffect(() => {
    if (userRole && !isIntermediary) {
      dispatch(getAgencies())
    }
  }, [userRole, isIntermediary])

  const isLoading = useMemo(
    () => isLoadingGetHelpRequests || isLoadingGetAgencies,
    [isLoadingGetHelpRequests, isLoadingGetAgencies]
  )

  const handleRowClick = useCallback(
    event => {
      window.open(
        `${ROUTES.HELP_REQUESTS}/tramites/${event?.row?.id}`,
        '_blank',
        'noopener,noreferrer'
      )
    },
    [page, pageSize, orderBy]
  )

  const HelpRequestFilter = useMemo(
    () => (
      <Filters
        handleFilterDrawer={toggleFilterDrawerOpen}
        isOpen={filterDrawerOpen}
        params={filters}
        open={filterDrawerOpen}
        setPage={setPage}
      />
    ),
    [filterDrawerOpen, filters]
  )

  const handleOrderBy = orderObj => {
    const order = orderObj[0]
    if (!order) {
      setOrderBy('createdAt')
      return
    }

    const { field, sort } = order
    setOrderBy(`${sort === 'desc' ? '-' : ''}${field}`)
  }

  return (
    <>
      {ready && HelpRequestFilter}
      <HeaderTitle title="Pedidos de ajuda / Atrasos justificados">
        <Button
          disabled={isLoading}
          variant="ghost"
          startIcon={<FilterIcon color={Theme.Colors.Primary.Base} />}
          onClick={toggleFilterDrawerOpen}
        >
          Filtros
        </Button>
      </HeaderTitle>
      <Grid className={styles.tableContainer}>
        <Grid className={styles.mainTable}>
          <Datagrid
            loading={isLoading}
            rows={helpRequests?.length ? helpRequests : []}
            columns={RequestsColumns(styles, isIntermediary)}
            paginationMode="server"
            onRowClick={handleRowClick}
            onPageChange={setPage}
            page={page}
            onPageSizeChange={setPageSize}
            pageSize={pageSize}
            rowCount={requestCount}
            onSortModelChange={handleOrderBy}
            sx={datagridStyle}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default HelpRequestsScreen
