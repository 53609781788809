import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { Grid } from '@material-ui/core'
import { Alert, Loader, Toast } from '@refera/ui-web'
import { navigate, useParams } from '@reach/router'
import { useSelector, useDispatch } from 'react-redux'
import WarningIcon from '@mui/icons-material/Warning'

import HeaderTitle from '_components/header-title'
import ModalDialog from '_components/modal/modal-dialog'
import ProcedureOverview from '_components/help-requests/procedure-overview'
import ProcedureTable from '_components/help-requests/procedure-table'
import useFetchCall from '_hooks/use-fetch-call'
import useRolePermission from '_hooks/use-role-permission'
import { ROUTES } from '_utils/constants'
import {
  getHelpRequestById,
  getHelpRequestPendings,
  GET_HELP_REQUEST_BY_ID,
  clearFeedbackMessage,
  EDIT_HELP_REQUEST,
  EDIT_HELP_REQUEST_PROCEDURE,
  CREATE_HELP_REQUEST_PROCEDURE,
} from '_modules/helpRequests/actions'
import {
  getHelpRequestsByIDSelector,
  getFeedbackMessageSelector,
} from '_modules/helpRequests/selectors'
import { userSelector } from '_modules/authentication/selectors'
import { currentServiceOrderSelector } from '_modules/service-orders/selectors'

import useStyles from './styles'

const HelpProceduresScreen = () => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { id: helpRequestId } = useParams()

  const userData = useSelector(userSelector)
  const serviceOrder = useSelector(currentServiceOrderSelector)
  const feedbackMessage = useSelector(getFeedbackMessageSelector)
  const helpRequest = useSelector(getHelpRequestsByIDSelector(helpRequestId))

  const { isAdmin, isIntermediary, isTradesman } = useRolePermission()
  const [isLoadingGetHelpRequest] = useFetchCall(GET_HELP_REQUEST_BY_ID.ACTION)
  const [toastMessage, setToastMessage] = useState('')
  const [modalDialog, setModalDialog] = useState({ isOpen: false, subTitle: '' })

  useEffect(() => {
    if (
      helpRequest &&
      Object.keys(helpRequest).length > 1 &&
      Object.values(userData).length > 1 &&
      !helpRequest?.intermediaryAvailable &&
      isIntermediary
    ) {
      navigate('/link-expirado')
    }
  }, [isIntermediary, helpRequest, userData])

  useEffect(() => {
    if (!helpRequest && helpRequestId && !isLoadingGetHelpRequest) {
      dispatch(getHelpRequestById(helpRequestId))
    }
  }, [helpRequest, helpRequestId, isLoadingGetHelpRequest])

  useEffect(() => {
    dispatch(getHelpRequestPendings(helpRequestId))
  }, [helpRequestId])

  const handleGoToMovidesk = useCallback(() => {
    if (isAdmin) {
      window.open(
        'https://refera.movidesk.com/kb/article/367569/o-que-pausa-um-chamado',
        '_blank',
        'noopener,noreferrer'
      )
      return
    }

    if (isIntermediary) {
      window.open(
        'https://refera.movidesk.com/kb/article/368897/o-que-e-um-chamado-pausado',
        '_blank',
        'noopener,noreferrer'
      )
      return
    }

    if (isTradesman) {
      window.open(
        'https://refera.movidesk.com/kb/article/367572/o-que-e-um-chamado-pausado',
        '_blank',
        'noopener,noreferrer'
      )
    }
  }, [isAdmin, isIntermediary, isTradesman])

  const handleCloseFeedbackMessage = useCallback(() => {
    dispatch(clearFeedbackMessage())
  }, [dispatch])

  const handleCloseToast = useCallback(() => setToastMessage(''), [])

  const onEditHelpRequestSucessful = useCallback(() => {
    setToastMessage('Ação realizada com sucesso')
  }, [])

  const onEditHelpRequestRejected = useCallback(() => {
    setToastMessage('Ocorreu um erro')
  }, [])

  const [isLoadingEditHelpRequest] = useFetchCall(
    EDIT_HELP_REQUEST.ACTION,
    onEditHelpRequestSucessful,
    onEditHelpRequestRejected
  )

  useFetchCall(EDIT_HELP_REQUEST_PROCEDURE.ACTION, onEditHelpRequestSucessful)
  useFetchCall(CREATE_HELP_REQUEST_PROCEDURE.ACTION, onEditHelpRequestSucessful)

  const isLoading = useMemo(
    () => isLoadingGetHelpRequest || isLoadingEditHelpRequest,
    [isLoadingGetHelpRequest, isLoadingEditHelpRequest]
  )

  return (
    <>
      <Loader open={isLoading} hasBackdrop label="Aguarde..." />
      <HeaderTitle title="Trâmites" backButtonAction={() => navigate(ROUTES.HELP_REQUESTS)} />
      <ProcedureOverview />
      <Grid className={styles.tableContainer}>
        {serviceOrder?.isVipClient && (
          <Alert
            severity="warning"
            title="ATENÇÃO: Chamado de um cliente VIP"
            icon={<WarningIcon fontSize="medium" color="warning" />}
          />
        )}
        <ProcedureTable isLoading={isLoading} />
        <Toast
          draggable
          open={toastMessage.length > 1}
          autoHideDuration={6000}
          onClose={handleCloseToast}
        >
          <Alert
            severity={toastMessage.includes('sucesso') ? 'success' : 'error'}
            title={toastMessage}
            onClose={handleCloseToast}
          />
        </Toast>

        <Toast
          open={Boolean(feedbackMessage?.length)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          autoHideDuration={5000}
          onClose={handleCloseFeedbackMessage}
        >
          <Alert
            severity="info"
            title={feedbackMessage}
            onClose={handleCloseFeedbackMessage}
            actionTitle="Clique aqui para saber mais"
            action={handleGoToMovidesk}
          />
        </Toast>
      </Grid>
      <ModalDialog modalDialog={modalDialog} setModalDialog={setModalDialog} />
    </>
  )
}

export default HelpProceduresScreen
